html {
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Cairo', sans-serif;
    background-size: 100%;
    background-position: bottom;
    background-color: #333333;
    min-height: 100%;
}

form {
    padding: 20px;
    margin-top: 60px;
}

form button,
form h5 {
    margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-color: #ec407a;
    box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label {
    color: #ec407a !important;
}